import React, { FC } from 'react'
import { Box, Container, Stack, Text, SimpleGrid } from '@chakra-ui/react'
import { useHubspotForm } from '@aaronhayes/react-use-hubspot-form';

interface Props {
  title: string
  description: string
}

const Newsletter: FC<Props> = ({ title, description }) => {
  useHubspotForm({
    portalId: process.env.NEXT_PUBLIC_HUBSPOT_PORTAL_ID,
    formId: process.env.NEXT_PUBLIC_HUBSPOT_NEWSLETTER_FORM_ID,
    target: '#newsletter-form'
  });

  return <Box bg="primaryGreen.600" px={{ base: '0.375rem', md: null }}>
    <Container as={Stack} maxW="6xl">
      <SimpleGrid maxW="6xl" py="xxl" templateColumns={{ base: '1fr', md: '4fr 5fr' }}>
        <Stack align="flex-start" mb={{ base: 'l', md: '0' }}>
          <Text
            color="baseWhite"
            fontFamily="Montserrat-SemiBold"
            fontSize="1.5rem"
            lineHeight="31px"
            letterSpacing=".68px"
          >
            {title}
          </Text>
          <Text
            color="baseWhite"
            fontFamily="Montserrat-SemiBold"
            fontSize=".875rem"
            lineHeight="18px"
            letterSpacing=".2px"
            marginTop="xs"
          >
            {description}
          </Text>
        </Stack>
        <Box id="newsletter-form" mt="-1rem" fontFamily="Montserrat-Medium" color="baseWhite" />
      </SimpleGrid>
    </Container>
  </Box>
}

export default Newsletter
