import React, { FC } from 'react'
import { Img, Link } from '@chakra-ui/react'

const Logo: FC<LinkGlobal.ImageLink> = ({ href, title, target }) => (
  <Link href={href} target={target} title={title}>
    <Img src="https://static1.carplanner.com/logos/carplanner.svg" alt={title} width="15rem" height="2.5rem" maxW="15rem" />
  </Link>
)

export default Logo
