import React, { FC } from 'react'
import { Stack, Text, Box, SimpleGrid } from '@chakra-ui/react'
import { CardButton, LimitedOfferBadge } from 'src/uikit'
import { GreenCarIconSecondary, GreenCalendarIcon, GreenPocketIcon } from 'src/icons'

const OfferDetails: FC<OfferGlobal.OfferDetails> = ({
  sectionTitle,
  durationTitle,
  durationValue,
  distanceTitle,
  distanceValue,
  depositTitle,
  depositValue,
  depositDescription,
  onHoverMessage,
  expirationValue,
  onClick,
}) => (
  <Stack>
    <Box textAlign="left">
      <Text
        color="baseDark"
        fontFamily="Montserrat-SemiBold"
        display="inline"
        fontSize="1rem"
        lineHeight="30.8x"
        letterSpacing="0.2px"
      >
        {sectionTitle}
      </Text>

      {expirationValue && expirationValue.length > 0 && (
        <LimitedOfferBadge display="inline" float="right">{expirationValue}</LimitedOfferBadge>
      )}
    </Box>

    <SimpleGrid columns={3} spacing=".688rem">
      <CardButton
        icon={<GreenCalendarIcon />}
        title={durationTitle}
        description={durationValue}
        onHoverMessage={onHoverMessage}
        onClick={onClick}
      />
      <CardButton
        icon={<GreenCarIconSecondary />}
        title={distanceTitle}
        description={distanceValue}
        onHoverMessage={onHoverMessage}
        onClick={onClick}
      />
      <CardButton
        icon={<GreenPocketIcon />}
        title={depositTitle}
        description={`${depositValue} ${depositDescription}`}
        onHoverMessage={onHoverMessage}
        onClick={onClick}
      />
    </SimpleGrid>
  </Stack>
)

export default OfferDetails
