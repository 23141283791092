import React, { FC } from 'react'
import dynamic from 'next/dynamic'
import { Stack, Flex, Heading, SimpleGrid } from '@chakra-ui/react'

const SliderBox = dynamic(() => import('src/uikit/Boxes/Slider'))

interface Props {
  title: string
  subtitle: string
  image: string
  boxes: Array<BoxGlobal.SliderBox>
}

const Slider: FC<Props> = ({ title, subtitle, image, boxes }) => (
  <Flex
    w="full"
    h={{ base: '29.375rem', md: '34.938rem' }}
    pb="9"
    background={`linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.1)), url(${image})`}
    backgroundColor="white"
    backgroundSize="cover"
    backgroundPosition="center top no-repeat"
  >
    <Stack mx="auto" marginTop={{ md: '12.5rem', sm: '2.5rem' }}>
      <Heading
        as="h1"
        color="baseWhite"
        fontFamily="Montserrat-SemiBold"
        fontSize={{ base: '1.5rem', md: '2.25rem' }}
        lineHeight={{ base: '1.938rem', md: '2.925rem' }}
        letterSpacing=".67px"
        whiteSpace="pre-wrap"
        my={{ base: 'xxl', md: '0' }}
        mx={{ base: 'safeAreaMobile', md: '0' }}
      >
        {title}
      </Heading>
      <Heading
        display={{ base: 'none', md: 'block' }}
        as="h2"
        color="baseWhite"
        fontFamily="Montserrat-Regular"
        fontSize="1.375rem"
        fontWeight="normal"
        lineHeight="1.75rem"
        letterSpacing=".6px"
        whiteSpace="pre-wrap"
        marginTop=".7rem !important"
        marginBottom="4rem !important"
      >
        {subtitle}
      </Heading>
      {boxes && (
        <SimpleGrid
          columns={{ base: 1, sm: 2, md: 2 }}
          spacing={{ base: '2.875rem', md: 120 }}
          position="relative"
        >
          {boxes.map(box => (
            <SliderBox
              key={box.title}
              image={box.image}
              title={box.title}
              description={box.description}
              action={box.action}
              actionMobile={box.actionMobile || null}
              href={box.href}
              alt={box.alt}
              target={box.target}
            />
          ))}
        </SimpleGrid>
      )}
    </Stack>
  </Flex>
)

export default Slider
