import React, { FC } from 'react'
import { Stack, Box, Link, Text, Flex, Icon } from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'

const DesktopItem: FC<MenuGlobal.MenuItem> = ({ name, title, href, target, description }) => (
  <Link
    href={href}
    title={title}
    role="group"
    display="block"
    p={2}
    rounded="md"
    target={target}
    _hover={{
      bg: 'primaryGreen.800',
    }}
  >
    <Stack direction="row" align="center">
      <Box>
        <Text
          transition="all .3s ease"
          _groupHover={{ color: 'baseWhite' }}
          color="baseNeutral.700"
          fontFamily="Montserrat-SemiBold"
          fontSize="0.875rem"
          letterSpacing="0.25px"
        >
          {name}
        </Text>
        <Text fontSize="sm">{description}</Text>
      </Box>
      <Flex
        transition="all .3s ease"
        transform="translateX(-10px)"
        opacity={0}
        _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
        justify="flex-end"
        align="center"
        flex={1}
      >
        <Icon color="baseWhite" w={5} h={5} as={ChevronRightIcon} />
      </Flex>
    </Stack>
  </Link>
)

export default DesktopItem
