import React, { FC } from 'react'
import Image from 'next/image'
import { Box, Text, SimpleGrid } from '@chakra-ui/react'

const Title: FC<OfferGlobal.OfferTitle> = ({ title, subtitle, brandImage, brandImageAlt }) => {
  return (
    <SimpleGrid templateColumns={{ base: '1fr', md: '0.25fr 1fr' }} alignItems="center">
      {brandImage &&
        <Box p={0} m={0} h="60px" display={{ base: 'none', md: 'block' }}>
          <Image objectFit="contain" src={brandImage} width="60" height="60" alt={brandImageAlt} />
        </Box>
      }
      <Box textAlign="left">
        <Text
          as="h1"
          color="baseDark"
          fontFamily="Montserrat-SemiBold"
          fontSize="1.75rem"
          letterSpacing="0.86px"
          mt="-7px"
        >
          {title}
        </Text>
        <Text
          marginTop="xs"
          color="baseNeutral.700"
          fontFamily="Montserrat-Medium"
          fontSize="1.125rem"
          textTransform="capitalize"
          lineHeight="23.4px"
          letterSpacing="0.3px"
        >
          {subtitle}
        </Text>
      </Box>
    </SimpleGrid>
  )
}

export default Title
