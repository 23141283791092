import React, { FC } from 'react'
import { SimpleGrid } from '@chakra-ui/react'
import { PrimaryButton, SecondaryButton } from 'src/uikit'

export declare type OfferActionsProps = (
  | {
    hasSecondaryAction?: boolean
    secondary: ButtonsGlobal.Button
  }
  | {
    hasSecondaryAction?: boolean
    secondary: never
  }
) &
  OfferGlobal.OfferAction

const Actions: FC<OfferActionsProps> = ({ active, primary, hasSecondaryAction, secondary, inline }) => {
  return (
    <SimpleGrid
      columns={{
        base: inline && hasSecondaryAction ? 2 : 1,
        md: inline ? 2 : 1
      }}
      spacing={{ base: '1rem', md: inline ? '1.5rem' : '.5rem' }}
    >
      {hasSecondaryAction && (
        <SecondaryButton
          title={secondary.title}
          action={secondary.action}
          onClick={secondary.onClick}
        />
      )}
      <PrimaryButton
        title={primary.title}
        action={primary.action}
        onClick={primary.onClick}
        disabled={!active}
      />
    </SimpleGrid>
  )
}

export default Actions
