import React, { FC } from 'react'
import { Stack, useColorModeValue } from '@chakra-ui/react'

import { MenuItemMobile } from 'src/components'

interface Props {
  items: MenuGlobal.MenuItem[]
}

const Mobile: FC<Props> = ({ items }) => (
  <Stack
    bg={useColorModeValue('baseWhite', 'gray.800')}
    textTransform="uppercase"
    fontSize="0.875rem"
  >
    {items.map(navItem => (
      <MenuItemMobile key={navItem.name} {...navItem} />
    ))}
  </Stack>
)

export default Mobile
