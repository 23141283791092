import React, { FC, forwardRef, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Container, Divider, Switch, Text } from '@chakra-ui/react'
import { SimpleList } from 'src/uikit'
import { showPriceVatIncluded } from 'src/redux/nlt-offer/actions'
import Title from './Title'
import Description from './Description'
import Offer from './Offer'
import Actions, { OfferActionsProps } from './Actions'

type Props = OfferGlobal.OfferTitle &
  OfferGlobal.OfferDetails &
  OfferGlobal.OfferDescription &
  OfferGlobal.OfferInsurances &
  OfferActionsProps & { ref: any }

const OfferDetails: FC<Props> = forwardRef(({
  title,
  subtitle,
  brandImage,
  brandImageAlt,
  sectionTitle,
  durationTitle,
  durationValue,
  distanceValue,
  distanceTitle,
  depositValue,
  depositDescription,
  depositTitle,
  expirationValue,
  onHoverMessage,
  onClick,
  fuelTitle,
  fuelValue,
  transmissionTitle,
  transmissionValue,
  powerTitle,
  powerValue,
  priceValue,
  priceDescription,
  insurances,
  active,
  primary,
  hasSecondaryAction,
  secondary,
}, ref) => {
  const dispatch = useDispatch()
  const priceVatIncluded = useSelector((state: Store.RootState) => state.nlt.priceVatIncluded)
  const [priceVatIncludedChecked, setPriceVatIncludedChecked] = useState(false);

  useEffect(() => setPriceVatIncludedChecked(!priceVatIncluded), [priceVatIncluded])

  const togglePriceVatIncluded = () => {
    setPriceVatIncludedChecked(!priceVatIncludedChecked)
    dispatch(showPriceVatIncluded(priceVatIncludedChecked))
  }

  // @ts-ignore
  return <Container maxW="6xl" px={0} ref={ref}>
    <Title title={title} subtitle={subtitle} brandImage={brandImage} brandImageAlt={brandImageAlt} />
    <Divider marginY="m" color="baseNeutral.700" />
    <Offer
      sectionTitle={sectionTitle}
      durationTitle={durationTitle}
      durationValue={durationValue}
      distanceValue={distanceValue}
      distanceTitle={distanceTitle}
      depositValue={depositValue}
      depositDescription={depositDescription}
      depositTitle={depositTitle}
      expirationValue={expirationValue}
      onClick={hasSecondaryAction ? onClick : null}
      onHoverMessage={onHoverMessage}
    />
    <Description
      fuelTitle={fuelTitle}
      fuelValue={fuelValue}
      transmissionTitle={transmissionTitle}
      transmissionValue={transmissionValue}
      powerTitle={powerTitle}
      powerValue={powerValue}
      priceValue={priceValue}
      priceDescription={priceDescription}
    />

    <Box mt="-30px" textAlign="right">
      <Text fontFamily="Montserrat-Medium" as="span">
        {priceVatIncluded ? `IVA inclusa` : `IVA esclusa`}
      </Text>
      <Switch
        ml="6px"
        colorScheme="green"
        isChecked={priceVatIncluded}
        float="right"
        value="1"
        onChange={togglePriceVatIncluded}
      />
    </Box>

    <Divider marginY="m" color="baseNeutral.700" />

    <Actions
      active={active}
      primary={primary}
      hasSecondaryAction={hasSecondaryAction}
      secondary={secondary}
      inline={false}
    />
    <SimpleList insurances={insurances} />
  </Container>
})

OfferDetails.displayName = 'OfferDetails'

export default OfferDetails
