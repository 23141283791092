export { default as Logo } from './Logo'

export { default as MenuItemMobile } from './MenuItem/Mobile'
export { default as MenuItemDesktop } from './MenuItem/Desktop'
export { default as MenuMobile } from './Menu/Mobile'
export { default as MenuDesktop } from './Menu/Desktop'

export { default as HeaderComponent } from './Header'
export { default as ContactComponent } from './Contact'
export { default as NewsletterComponent } from './Newsletter'

export { default as SliderComponent } from './Slider'

export { default as OfferDetails } from './OfferDetails'

export { default as Container } from './Container'
