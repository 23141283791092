import React, { FC } from 'react'
import { Box, Flex, IconButton, Collapse, Spacer, useDisclosure } from '@chakra-ui/react'
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons'
import { MenuDesktop, MenuMobile, Logo } from 'src/components'

interface Props {
  href: string
  title: string
  menuItems: MenuGlobal.MenuItem[]
}

const Header: FC<Props> = ({ href, title, menuItems }) => {
  const { isOpen, onToggle } = useDisclosure()

  return (
    <Box className="header-container">
      <Box
        bg="baseWhite"
        color="baseNeutral.700"
        py={{ base: 'xs', md: 'xl' }}
        px={{ base: 'm', md: 'xxl3' }}
        borderBottom={0}
        textAlign="center"
        boxShadow="0 2px 4px 0px rgba(0, 0, 0, 0.08)"
      >
        <Box
          display={{ base: 'block', md: 'none' }}
          flex="auto"
          ml={{ base: -2 }}
          float="left"
        >
          <IconButton
            onClick={onToggle}
            icon={isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />}
            variant="ghost"
            aria-label="Toggle Navigation"
          />
        </Box>

        <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
          <Logo href={href} title={title} />
          <>
            <Spacer display={{ base: 'none', md: 'flex' }} />
            <Flex ml={10} display={{ base: 'none', md: 'flex' }}>
              <MenuDesktop items={menuItems} />
            </Flex>
          </>
        </Flex>
      </Box>

      <Box display={{ base: 'block', md: 'none' }}>
        <Collapse in={isOpen} animateOpacity>
          <MenuMobile items={menuItems} />
        </Collapse>
      </Box>
    </Box>
  )
}

export default Header
