import React, { FC } from 'react'
import Image from 'next/image'
import { Container, Box, Stack, Text, SimpleGrid } from '@chakra-ui/react'
import { InvertedLinkButton } from 'src/uikit'
import cities from 'src/containers/data/cities.json'

interface Props {
  image: string
  title: string
  description: string
  action?: string
  href?: string
  alt?: string
}

const citiesList = cities.map(city => `<a href="${city.href}">${city.name}</a>`)

const Contact: FC<Props> = ({ image, title, description, action, href, alt }) => (
  <Box bgColor="#EFEFF1">
    <Container as={Stack} maxW="6xl">
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing="l">
        <Stack align="flex-start" pt="xxl3" pb="xxl3">
          <Text
            color="baseDark"
            fontFamily="Montserrat-SemiBold"
            fontSize="1.25rem"
            letterSpacing="0.6px"
            lineHeight="26px"
            whiteSpace="pre-wrap"
            mb="m"
          >
            {title}
          </Text>
          <Text
            color="baseDark"
            fontFamily="Montserrat-Medium"
            fontSize="0.875rem"
            letterSpacing="0.2px"
            lineHeight="18px"
            whiteSpace="pre-wrap"
            pb="l"
          >
            {description}
            <br />
            <span
              dangerouslySetInnerHTML={{ __html: citiesList.join(', ') }}
            />
            .
          </Text>
          {action && href &&
            <InvertedLinkButton href={href} action={action} title={alt} />
          }
        </Stack>
        <Stack align="flex-start" display={{ base: 'none', md: 'block' }} position="relative">
          <Image src={image} alt={title} layout="fill" loading="lazy" placeholder="empty" decoding="async" />
        </Stack>
      </SimpleGrid>
    </Container>
  </Box>
)

export default Contact
