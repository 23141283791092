import React, { FC } from 'react'
import { Box } from '@chakra-ui/react'
import { BadgeWithIcon, PriceBox } from 'src/uikit'
import { GreenFuelIcon, GreenTransmissionIcon, GreenPowerIcon } from 'src/icons'

const BadgeWithIconCustom = ({ icon, name, value, ...others }) => (
  <BadgeWithIcon
    icon={icon}
    name={name}
    value={value}
    fontFamily="Montserrat-Regular, san-serif"
    fontSize=".875rem"
    color="#262626"
    marginBottom="6px"
    {...others}
  />
)

const Description: FC<OfferGlobal.OfferDescription> = ({
  fuelTitle,
  fuelValue,
  transmissionTitle,
  transmissionValue,
  powerTitle,
  powerValue,
  priceValue,
  priceDescription,
}) => (
  <Box
    textAlign="left"
    position="relative"
    marginTop="1.3rem!important"
    marginBottom="0"
    marginLeft="-3px"
  >
    <BadgeWithIconCustom icon={<GreenFuelIcon />} name={fuelTitle} value={fuelValue} />
    <BadgeWithIconCustom
      icon={<GreenTransmissionIcon />}
      name={transmissionTitle}
      value={transmissionValue}
      marginLeft="-2px"
    />
    <BadgeWithIconCustom icon={<GreenPowerIcon />} name={powerTitle} value={powerValue} />

    <PriceBox
      value={priceValue}
      description={priceDescription}
      textAlign="right"
      position="absolute"
      top="-3px"
      right="0"
    />
  </Box>
)

export default Description
