import React, { FC } from 'react'
import {
  Box,
  Collapse,
  Flex,
  Icon,
  Link,
  Stack,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'

const MobileItem: FC<MenuGlobal.MenuItem> = ({
  name,
  title,
  href,
  target,
  children,
}) => {
  const { isOpen, onToggle } = useDisclosure()

  const handleToggle = () => {
    if (children) {
      onToggle()
    }
  }

  const hasChildren = children && children.length > 0

  return hasChildren ?
    (
      <Stack spacing={4} p="m" py="s" className="menu-item-mobile">
        <Flex
          py={2}
          title={title}
          justify="space-between"
          align="center"
          _hover={{
            textDecoration: 'none',
          }}
        >
          <Link
            title={title}
            target={target}
            fontFamily="Montserrat-SemiBold"
            color="baseNeutral.700"
            href={href}
            dangerouslySetInnerHTML={{
              __html: name,
            }}
          />
          <Icon
            as={ChevronRightIcon}
            color="baseNeutral.700"
            transition="all .25s ease-in-out"
            transform={isOpen ? 'rotate(90deg)' : ''}
            w={6}
            h={6}
            onClick={handleToggle}
          />
        </Flex>

        <Box mt="0 !important">
          <Collapse in={isOpen} animateOpacity>
            <Stack
              pl={4}
              borderLeft={1}
              borderStyle="solid"
              borderColor={useColorModeValue('gray.200', 'gray.700')}
              align="start"
            >
              {children.map(child => (
                <Link key={child.name} href={child.href} title={child.title} target={child.target} py={2} dangerouslySetInnerHTML={{
                  __html: child.name,
                }}
                />
              ))}
            </Stack>
          </Collapse>
        </Box>
      </Stack>
    )
    :
    (
      <Stack p="m" py="s" className="menu-item-mobile">
        <Flex
          title={title}
          justify="space-between"
          align="center"
          _hover={{
            textDecoration: 'none',
          }}
        >
          <Link
            key={name}
            title={title}
            target={target}
            py={2}
            fontFamily="Montserrat-SemiBold"
            color="baseNeutral.700"
            href={href}
            dangerouslySetInnerHTML={{
              __html: name,
            }}
          />
        </Flex>
      </Stack>
    )
}

export default MobileItem
