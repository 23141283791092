import React, { FC, ReactNode } from 'react'
import { Container as ChakraContainer, Stack } from '@chakra-ui/react'

interface Props {
  children: ReactNode
  paddingY?: string
  marginY?: string
  paddingX?: string
  marginX?: string
}

const Container: FC<Props> = ({ children, paddingY, paddingX, marginY, marginX }) => (
  <ChakraContainer
    as={Stack}
    maxW="7xl"
    py="xxl6"
    paddingY={paddingY || 10}
    marginY={marginY}
    paddingX={paddingX || 0}
    marginX={marginX}
  >
    {children}
  </ChakraContainer>
)

export default Container
